/* Add this to your CSS file */

::-webkit-scrollbar {
    height: 6px;
    display: none;
    &:hover{
        display: block;
    }
  }
  
  ::-webkit-scrollbar-thumb {
    background: #B1CF4B; 
    border-radius: 8px;

    &:hover{
        background: #B1CF4B; 

    }

  }
  
  .package{
    &::-webkit-scrollbar {
        height: 6px;
        display: none;
      }
    .package_nav{
        &:hover{
        ::-webkit-scrollbar {
                height: 6px;
                display: block;
                
              }
        }
    }
    
      
    &::-webkit-scrollbar-thumb {
        background: #B1CF4B; 
        border-radius: 8px;
        &:hover{
            background: #B1CF4B; 
    
        }
    
      }

      .cardpackage{
        &:hover{
          .image{
            filter: brightness(50%);
          }
        }
      }
    
  }