// SCSS for fade animations

$animation-duration: 0.5s;
$animation-ease: ease-in-out;

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.content-slide {
  opacity: 0;
  animation: fadeIn $animation-duration $animation-ease forwards;

  &.animate-fadeIn {
    animation: fadeIn $animation-duration $animation-ease forwards;
  }

  &.animate-fadeOut {
    animation: fadeOut $animation-duration $animation-ease forwards;
  }
}






