/* Import Tailwind's base, components, and utilities */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Custom styles for DatePicker */
.react-datepicker-wrapper {
  @apply w-full;
  &.w-50 {
    @apply w-1/2; // Example width of 50%
  }
  &.w-75 {
    @apply w-3/4; // Example width of 75%
  }
  &.w-100 {
    @apply w-full; // Full width by default
  }
}
.react-datepicker {
  @apply bg-white w-full border border-primeColor rounded-lg shadow-lg;
}

.react-datepicker__time-container {
  @apply bg-primeColor border-t-2 border-gray-300  rounded-lg shadow-lg;
}

.react-datepicker__time-list {
  @apply py-2 px-4;
}

.react-datepicker__time-list-item {
  @apply py-2 px-3  hover:bg-primeColor hover:text-white cursor-pointer transition duration-200 ease-in-out;
}

.react-datepicker__time-list-item--selected {
  @apply bg-primeColor text-white font-bold;
}

.react-datepicker__header {
  @apply bg-secondColor text-white font-semibold py-2 rounded-t-lg;
}

.react-datepicker__triangle {
  border-bottom-color: #1C68AA ;
  display: none; 
  /* Custom color for the triangle */
}
