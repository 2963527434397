$prime-color:#B1CF4B;
$second-color:#1C68AA;
$third-color:#1C3447;
$background-color:#FFF;
$background2-color:#E7F1C9;

@mixin inputs($height, $width) {
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1) inset;
    width: $width;
    height: $height;
    border-radius: 8px;
    outline: none;
    background-color: $background-color;
    color: $second-color;
    border: none;
    caret-color: $second-color; /* Input caret design */
    &::placeholder {
        font-size: 10px;
        font-weight: 500;
        padding-left: 10px;
        color: $second-color; /* Placeholder color update */
        opacity: 0.7; /* Placeholder opacity update */
    }
}


@mixin respond-to($size) {
    @if $size == 'small' {
      @media (max-width: 640px) { @content; }
    } @else if $size == 'medium' {
      @media (max-width: 768px) { @content; }
    } @else if $size == 'large' {
      @media (max-width: 1024px) { @content; }
    }
  }

@mixin formStyle($height,$gap){
            height:$height;
            @include flexstyle(column,center,center ,$gap)
}


@mixin font-height($line_height,$size,$weight){
    line-height: $line_height;
    font-size: $size;
    font-weight: $weight;

}

