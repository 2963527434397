@use './helper/mixin' as *;
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');

html {
  scroll-behavior: smooth;
}
body {
    //font-family: $font-primary;
    //color: $text-color;
    background-color: $background-color;
    margin: 0;
    padding: 0;
  }
  
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    // font-family: $font-secondary;
  }

.App {
text-align: center;
}

.App-logo {
height: 40vmin;
pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
.App-logo {
    animation: App-logo-spin infinite 20s linear;
}
}

